var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"visitNode",staticClass:"person-visit"},[_c('div',{staticClass:"trip-time"},[_c('span',{staticClass:"title"},[_vm._v("到访轨迹")]),_c('div',{staticClass:"year"},[_vm._v(_vm._s(_vm.$moment(_vm.trip.firstAppearTime).format('YYYY-MM-DD')))]),(_vm.isSearchArea)?_c('div',{staticClass:"hour"},[_vm._v(" "+_vm._s(_vm.$moment(_vm.trip.firstAppearTime).format('HH:mm:ss'))+" — "+_vm._s(_vm.trip.lastAppearTime ? _vm.$moment(_vm.trip.lastAppearTime).format('HH:mm:ss') : '至今')+" ")]):_vm._e(),_c('el-button',{staticClass:"click-btn",attrs:{"type":"text","loading":_vm.videoLoading},on:{"click":function($event){return _vm.lookVideo()}}},[_vm._v(_vm._s(_vm.videoLoading ? '加载中' : '查看到访视频'))])],1),_c('div',{ref:"lineNode",staticClass:"trip-line"},[_c('div',{staticClass:"time-line-wrap"},[_c('div',{ref:"timeLine",staticClass:"time-line"},[_vm._l((_vm.trip.trips),function(d,index){return (d)?[_c('ul',{staticClass:"time-start",class:{ first: index === 0 }},[_vm._m(0,true)]),_vm._l((d.trace),function(item,i){return [(item.path.length > 0)?_c('ul',{staticClass:"time-item pass",class:{
                'disabled-pass': item.path.length > 0 && item.path[0].status === 'disabled'
              }},[_c('li',{staticClass:"name"},_vm._l((item.path),function(m,index){return _c('span',[_vm._v(" "+_vm._s(m.name)+" "),(index < item.path.length - 1)?_c('i',[_vm._v("-")]):_vm._e()])}),0)]):_vm._e(),_c('ul',{staticClass:"time-item"},[_c('div',{staticClass:"trace-point",class:{
                  'active-item': _vm.activeIndex === item.id + '-' + index + '-' + i,
                  'end-item': !d.endTime && i === d.trace.length - 1,
                  'disabled-item': item.status && item.status === 'disabled'
                },on:{"click":function($event){return _vm.clickAccessPoint(item, index, i)}}}),(item.name)?_c('li',{staticClass:"name"},[_c('el-tooltip',{attrs:{"content":item.name,"placement":"top","open-delay":500}},[_c('span',[_vm._v(" "+_vm._s(item.name.substring(0, 14))+" "),(item.name.length > 14)?_c('i',[_vm._v("...")]):_vm._e()])])],1):_vm._e(),(item.startTime)?_c('li',{staticClass:"ts"},[_vm._v(" 时间： "),_c('span',[_vm._v(" "+_vm._s(item.startTime ? _vm.$moment(item.startTime).format('HH:mm:ss') : '-')+" ")])]):_vm._e(),_c('li',{staticClass:"ts"},[_vm._v(" 停留： "),_c('span',[_vm._v(_vm._s(_vm.getDuration(item.duration, _vm.timeType.TWO_SECTIONS, true)))])])])]}),(d.endTime)?_c('ul',{staticClass:"time-end",class:{ first: index === _vm.trip.trips.length - 1 }},[_vm._m(1,true)]):_vm._e()]:_vm._e()})],2)]),_c('img',{staticClass:"move-left btn",attrs:{"src":_vm.tripLeft
          ? require('@/assets/images/dashboard/left-arrow-green.png')
          : require('@/assets/images/dashboard/left-arrow-black.png')},on:{"click":function($event){return _vm.move(1)}}}),_c('img',{staticClass:"move-right btn",attrs:{"src":_vm.tripRight
          ? require('@/assets/images/dashboard/right-arrow-green.png')
          : require('@/assets/images/dashboard/right-arrow-black.png')},on:{"click":function($event){return _vm.move(-1)}}})]),_c('div',{staticClass:"area-imageUrl"},[_c('span',{staticClass:"trip-time"},[_vm._v("区域抓拍图("+_vm._s(_vm.areaName)+")")]),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"area-image-wrap",attrs:{"element-loading-background":"rgba(256, 256, 256, 0)"}},[(_vm.areaImageDetails.length > 0)?_c('div',{staticClass:"detail-content"},[_c('div',{ref:"imageLine",staticClass:"image-line"},[_c('ul',{staticClass:"detail-list"},_vm._l((_vm.areaImageDetails),function(item,index){return _c('li',{key:index},[_c('div',{staticClass:"img-box",on:{"mouseenter":function($event){return _vm.imageHover(index, true)},"mouseleave":function($event){return _vm.imageHover(index, false)}}},[(item.imageUrl)?_c('feature-img',{staticClass:"best-img",attrs:{"src":item.imageUrl,"cover":true}}):_c('div',{staticClass:"icon-bg"},[_c('i',{staticClass:"iconfont icon-renlianqueshengtu"})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.hoverImageIndex === index),expression:"hoverImageIndex === index"}],staticClass:"click-see",on:{"click":function($event){return _vm.imageClick(item)}}},[_vm._v(" 查看 ")])],1),_c('p',[_vm._v(" 日期: "+_vm._s(item.createdAt ? _vm.$moment(item.createdAt).format('YYYY-MM-DD') : '-')+" ")]),_c('p',[_vm._v(" 抓拍时间: "+_vm._s(item.createdAt ? _vm.$moment(item.createdAt).format('HH:mm:ss') : '-')+" ")])])}),0)]),_c('img',{staticClass:"move-left btn",attrs:{"src":_vm.imageLeft
              ? require('@/assets/images/dashboard/left-arrow-green.png')
              : require('@/assets/images/dashboard/left-arrow-black.png')},on:{"click":function($event){return _vm.moveImage(1)}}}),_c('img',{staticClass:"move-right btn",attrs:{"src":_vm.imageRight
              ? require('@/assets/images/dashboard/right-arrow-green.png')
              : require('@/assets/images/dashboard/right-arrow-black.png')},on:{"click":function($event){return _vm.moveImage(-1)}}})]):_vm._e(),(_vm.areaImageDetails.length === 0 && !_vm.loading)?_c('p',{staticClass:"no-partner",staticStyle:{"margin-left":"20px"}},[_vm._v(" 此区域无抓拍图 ")]):_vm._e()])]),_c('div',{staticClass:"partner-box"},[_c('p',{staticClass:"title"},[_vm._v("到访同行人")]),(_vm.partnerList.length > 0)?_c('ul',{staticClass:"partner-list"},_vm._l((_vm.partnerList),function(item,index){return _c('li',{key:index,ref:'partnerNode' + index,refInFor:true,on:{"mouseenter":function($event){return _vm.partnerHover(index, true)},"mouseleave":function($event){return _vm.partnerHover(index, false)}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.hoverIndex === index),expression:"hoverIndex === index"}],staticClass:"click-see",on:{"click":function($event){return _vm.personClick(item)}}},[_vm._v("查看")]),(item.imageUrl)?_c('feature-img',{staticClass:"best-img",attrs:{"src":item.imageUrl,"cover":true}}):_c('div',{staticClass:"icon-bg"},[_c('i',{staticClass:"iconfont icon-renlianqueshengtu"})])],1)}),0):_c('p',{staticClass:"no-partner"},[_vm._v("无同行记录")])]),(_vm.showFlag)?_c('access-image',{attrs:{"title":'抓拍图片',"showFlag":_vm.showFlag,"personImages":_vm.personImg},on:{"close":function($event){_vm.showFlag = false}}},[_c('div',{staticClass:"image-detail",attrs:{"slot":"detail"},slot:"detail"},[_c('p',[_vm._v("抓拍区域: "+_vm._s(_vm.imageDetail.areaName))]),_c('p',[_vm._v(" 抓拍时间: "+_vm._s(_vm.imageDetail.createdAt ? _vm.$moment(_vm.imageDetail.createdAt).format('YYYY-MM-DD HH:mm:ss') : '-')+" ")])])]):_vm._e(),(_vm.showVideo)?_c('visit-video',{ref:"videoNode",attrs:{"showDetail":_vm.showVideo,"title":'到访视频'},on:{"close":function($event){_vm.showVideo = false}}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"box"},[_c('span',[_vm._v("进场")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"box"},[_c('span',[_vm._v("离场")])])}]

export { render, staticRenderFns }